import React from "react";


const UnAuth=()=>{

    return(
        <div>
            <h2>403-Access Forbidden</h2>
            <b>Invalid token value</b>
            <br/> <br/>
            <img src="https://www.online-tech-tips.com/wp-content/uploads/2021/06/http-403.jpeg" height={200} width={300}></img>
        </div>
    )
}

export default UnAuth;