import { useState } from 'react';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';

import {pdfjs } from 'react-pdf';
import pdfFile from './Maxis-AIA-Bill-Protection-PDS.pdf';
import MobilePdf from './mobilePdfViewer';
import "./commonStyles.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const Pdf = ({url}) => {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const goToPrevPage = () =>
		setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

	const goToNextPage = () =>
		setPageNumber(
			pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
		);

	return (
		<div 
		// style={{ maxWidth: '300px', overflowX: 'auto' }}
		>
			{/* <nav>
				<button onClick={goToPrevPage} >Prev</button>
				<button onClick={goToNextPage} className='next-btn'>Next</button>
				<p>
					Page {pageNumber} of {numPages}
				</p>
			</nav>

			<Document
				file={url}
				onLoadSuccess={onDocumentLoadSuccess}
			>
				<Page pageNumber={pageNumber} scale={1}
				customStyle={{ width: "300px" }}
				/>
			</Document> */}
			<MobilePdf url={url}/>
		</div>
	);
};

export default Pdf; 
