import React from 'react';
import timeoutImage from "../../images/timeoutImage.PNG";
const Timeout=()=>{

    return(
        <div>
            {/* <h2>Timeout</h2> */}
            <img height={100} width={300} style={{marginTop:'6em'}} src={timeoutImage}/>
        </div>
    )
}

export default Timeout;