import React from "react";

import './shop.css';

const Shop = () => {
    return (
        <h1>Shop Page</h1>
    )
}

export default Shop;