import axios from "axios";
import { toast } from 'react-toastify';
import secureLocalStorage from "react-secure-storage";
import { Navigation } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


// let navigate = useNavigate();


console.log(process.env,"API_BASE_URL++")

//FOR DEV
// let baseUrl=process.env.REACT_APP_DEV_BASE_URL;

//FOR PROD
let baseUrl=process.env.REACT_APP_PROD_BASE_URL

axios.defaults.baseURL = baseUrl;
// axios.defaults.headers.common['Authorization'] = "AUTH_TOKEN";
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.defaults.headers.common['X-API-KEY'] = process.env.REACT_APP_X_API_KEY;
axios.defaults.headers.common['X-API-AUTH-WHO'] = "InsureTech";


axios.interceptors.response.use(
    response => {
        console.log(response,"response interceptorrrr");
      return response
    },
    function (error) {

      console.log(error,"respserrr")
if(error.response?.status==403){
  console.log(error.response?.data?.message,"errrespppppp");
  if(error.response?.data?.message==="Invalid token value"){
    window.location.replace("/unauthorized");
  }else{
  window.location.replace("/timeout");
  }
}

if(error.response?.status==401){
  window.location.replace("/unauthorized");
}

      // console.log(error.response.status==403,"error interceptorrrr++++++");
      console.log(error.response?.data?.message,"messageeeeeee")
        console.log(error.message,"error interceptorrrr");
        toast.error(error.message + " Error!");
      const originalRequest = error.config
    }
);

export default axios;