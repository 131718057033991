import  React,{useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PdfViewer from './pdfViewre';
import MobilePdfViewer from "./mobilePdfViewer";
import {TERMS_OF_USE} from "../../constents/TandC/termsOfUse";
import {PRIVACY_STATMENT} from "../../constents/TandC/privacyStatment";
import {IMPORTENT_NOTICE} from "../../constents/TandC/importentNotice";
import {DECLARATION} from '../../constents/TandC/declaration';
import {POLICY_WORDING} from "../../constents/TandC/policyWording";
import billProtPdf from "./Maxis-AIA-Bill-Protection-Policy.pdf";
import productDisclosure from "./Maxis-AIA-Bill-Protection-PDS.pdf"
import './commonStyles.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "96%",
  height:"90%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: "scroll"
};

export default function BasicModal({url,open,handleOpen,handleClose,type}) {
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

  const [htmlData,setHtmlData]=useState("");
  const [pageUrl,setPageUrl]=useState();
const [data,setData]=useState({});


console.log(type,"typeeeeeeee");

useEffect(()=>{
  
  setPageUrl(url);

// if(type=='terms-of-use'){
//   setData(TERMS_OF_USE);
// }else if(type=='privacy-statement'){
//   setData(PRIVACY_STATMENT);
// }else if(type=='important-notice-conventional'){
//   setData(IMPORTENT_NOTICE);
// }else if(type=='declaration-conventional'){
//   setData(DECLARATION);
// }
// else if(type=='Maxis-AIA-Bill-Protection-Policy'){
//   setPageUrl(url)
// }else if(type=='Maxis-AIA-Bill-Protection-PDS'){
//   setPageUrl(url)
// }
})

  function downloadHtml(url) {
    console.log(url,"url in modellllll")
    fetch(url)
      .then(response => response.text())
      .then(data => {
        let replacedData=data.replace("/etc/", "/dev/etc/");
        // console.log(data,"data======================");

          setHtmlData(`<base href=${url} />${replacedData}`)
      })
      .catch(error => console.error(error));
  }

// useEffect(()=>{
//     // window.location.replace(url)
//     downloadHtml(url);
// },[url])

// useEffect(()=>{

// },[url])

const frame = document.getElementById('dataFrame');
console.log("fffrrrrtttt1")
frame?.contentWindow?.postMessage("frameData", 'https://www.aia.com.my/en/index/terms-of-use.html');

window.addEventListener('message', event => {
    // IMPORTANT: check the origin of the data!
    console.log(event.origin,"originnnnnnnnnn")
    if (event.origin === 'http://localhost:3000') {
        // The data was sent from your site.
        // Data sent with postMessage is stored in event.data:
        console.log("fffrrrrtttt22222")
        console.log(event.data,"-----2");
        return true;
    } else {
        console.log("fffrrrrtttt333")
        console.log(event.data,"-----");
        // The data was NOT sent from your site!
        // Be careful! Do not use it. This else branch is
        // here just for clarity, you usually shouldn't need it.
        return;
    }
});


  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 8,
            color:"gray",
            zIndex:1020
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize='large' />
        </IconButton>


       {/* {type=='Maxis-AIA-Bill-Protection-Policy' || type=='Maxis-AIA-Bill-Protection-PDS' ? 
         <PdfViewer url={pageUrl} /> :
         <>
 <h1 className='model-header'>{data?.header}</h1>
 <hr/>
 <div dangerouslySetInnerHTML={{__html: data?.text}}></div>
 </>
        } */}
 {/* <p>{TERMS_OF_USE?.text}</p> */}


 <PdfViewer url={pageUrl}/>

            {/* {type=='pdf' ?  */}
            {/* {type=='Maxis-AIA-Bill-Protection-Policy' || type=='Maxis-AIA-Bill-Protection-PDS'?
        //  <PdfViewer url={url} />   
         <PdfViewer url={pageUrl}/>
        //  <MobilePdfViewer url={url}/> //need to remove
            :
        // <div dangerouslySetInnerHTML={{__html: htmlData}}></div>
 <iframe src={url} name="example" height="500" width="100%" > <script> let win = window.frames.example; win.postMessage("message", "*"); </script></iframe> 
  } */}
        </Box>
      </Modal>
    </div>
  );
}

