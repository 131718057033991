import React from "react";
import  secureLocalStorage  from  "react-secure-storage";
import { toast } from 'react-toastify';
import './accountSelection.css';
import { useState, useEffect } from 'react';
import NumberTwo from '../../images/num22.svg';
import DisableCard from "./disableCard";


// Component that displays the description for number selection
const NumSelectionDesc = () => {
    return (
        <div className="-Description">
            <div className="number-row2">
            <span className="number">2</span>
                {/* <img src={NumberTwo} class="number-icon" alt=""></img> */}
            </div>
            <div className="desc-row">
                <div className="acc-selection-text">
                    <span class="Account-selection"> Number selection</span><br></br>
                </div>
                <div className="select-text" style={{textAlign:"start"}}>
                    <p class="Select-an-account-to">Please select a mobile number to sign up for bill protection.</p>
                </div>
            </div>
        </div>
    )
}

const AccountList = (props) => {
    // state for the selected radio button
    const [checked, setChecked] = useState("");
    // state for the selected checkboxes
    const [selectedMsisdn, setSelectedMsisdn] = useState(""); 

    useEffect(()=>{
      secureLocalStorage.setItem("account_no","");
      secureLocalStorage.setItem("msisdn","");
      props.accNoAndMsisdnChange(checked,selectedMsisdn);
    },[])

    // function to handle the change of radio button
    function handleRadioChange(accountNo) {
      try{
      setChecked(accountNo);
      secureLocalStorage.setItem("account_no", accountNo);
      props.accNoAndMsisdnChange(accountNo,selectedMsisdn);

      setSelectedMsisdn("");
      secureLocalStorage.setItem("msisdn", "");
      props.accNoAndMsisdnChange(checked,"");

    }catch(err){
      toast.error(err);
    }
    }

    function handleMsisdnChange(msisdn) {
      console.log(msisdn,"msisdnnnn")
        setSelectedMsisdn(msisdn);
        // sessionStorage.setItem('msisdn', event.target.value);
        secureLocalStorage.setItem("msisdn", msisdn);
        props.accNoAndMsisdnChange(checked,msisdn);
      }


    // If props data is not available, show message
    if (!props.data?.length) {
      return <div>
        {/* Data is not available */}
        </div>;
    }

    // sessionStorage.setItem('account_no',checked)

console.log(props.data,"props.data0000")

    return (
      <div>
        <div className="Mask">
            {/* container element for the account number list */}
            <div className="-accNumList-container">
            {/* maps through the "accounts" array and renders a radio button for each item */}
            {props.data?.map((item, index, array) => (
              <>
              {item.active_policy ? <DisableCard item={item} /> : 
            <div className="checklist-column" >
            <div className="checkbox" 
            onClick={()=>handleRadioChange(item.account_no)}
            >
                <input
                name="radio-button"
                className="radio-custom"
                key={item.account_no}
                id={item.account_no}
                type="radio"
                value={item.account_no}
                checked={item.account_no === checked}
                // onChange={()=>handleRadioChange(item.account_no)}
                />
            </div>
            <div className="accNum-row" border={index < array.length - 1}>
                <div className="accNum">{item.account_no}</div>
                <div className="telNum">{item.msisdn_list.map((msisdn) => msisdn.msisdn).join(', ')}</div>
            </div>
            
            </div>  
              }
              </>
            ))}
            </div>
        </div>


        <NumSelectionDesc />
        
        <div className="Mask">
            <div className="-accNumList-container">
            {/* filters the "accounts" array based on the value of the "checked" state variable and maps through the remaining items */}
            {props.data
            ?.filter(item => item.account_no === checked) 
            .map((item, index) => 
            // maps through the "msisdn_list" array for each filtered item and renders a checkbox input for each msisdn
              item.msisdn_list.map((msisdn, i) => (
                <label key={msisdn.msisdn}>
                    {/* checkbox-column element with a dynamic class for bottom border */}
                    <div className={`checkbox-column ${i < item.msisdn_list.length - 1 ? 'border-bottom' : ''}`} >
                        <div className="checkbox" onClick={()=>handleMsisdnChange(msisdn.msisdn)}>
                        <input
                            name="msisdn-group"
                            className="radio-custom"
                            key={msisdn.msisdn}
                            id={msisdn.msisdn}
                            type="radio"
                            value={msisdn.msisdn}
                            checked={msisdn.msisdn === selectedMsisdn}
                            // onChange={()=>handleMsisdnChange(msisdn.msisdn)}
                        />
                        </div>
                    <div className="accNum-row">
                        <div className="accNum">{msisdn.msisdn}</div>
                        <div className="planName">{msisdn.plan_name}</div>
                    </div>
                 </div>
                  
                </label>
              ))
            )}

            </div>
        </div>
      </div>
    );
  };
  

export default AccountList;


