import React,{useState,} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './commonStyles.css';

export default function AlertDialog({data,open,confirnClick,handleClose}) {

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='dialog-boxxxxxxxx'
      >
        <DialogTitle id="alert-dialog-title">
          {data.header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
         {data.text}
          </DialogContentText>
        </DialogContent>


        <Button
        id="ok-btn"
        size="small"
        style={{ backgroundColor:  "#3e4a56", borderRadius: "1em" }}
        onClick={confirnClick}
      >
        <span className="buy-now-btn-text">{data.sucessBtnText?data.sucessBtnText:"OK"}</span>
      </Button>
      
      <Button
        id="cancel-btn"
        size="small"
        onClick={handleClose}
        style={{ backgroundColor: "white", borderRadius: "1em" }}
      >
        <span className="cancel-text">CANCEL</span>
      </Button>

        {/* <DialogActions>
          <Button onClick={confirnClick}></Button>
          <Button onClick={handleClose} autoFocus>
          CANCEL
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
