import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Container, Box, Grid, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
// import ReactGA from 'react-ga';
import PlanCard from "./planCard";
// import {eventTrack} from '../../commonFunctions/ga-event';
import "./planSelection.css";
import AiaLogo from "../../images/aia-logo.svg";
import DialogeBox from "../commonComponents/dialog";
import Loader from "../commonComponents/loader";
import { GET, POST } from "../../services/commonServices";
import {
  // GET_PLAN_DETAILS,
  VALIDATE_USER,
  GET_PLANS_DATA,
  PURCHASE_SUBMISSION,
} from "../../constents/apiEndPoints";
// import { redirection } from "../../commonFunctions/timeoutRedirection";
import { toast } from "react-toastify";
import BackButton from "../commonComponents/backButton";

let dummyData = {
  order_id: "ASDAS-ASDAS3asda-34sa-34waddfd",
  account_no: "549874",
  average_bill: "31055",
  average_bill_unit: "mth",
  active_policy: false,
  plans: [
    {
      pricing_plan_id: "asd-we43-sda3-aa33sd-4",
      amount_bill: "300",
      amount_bill_unit: "mth",
      title: "6 months bill coverage",
      description: "<b>up to 3 mth</b> max payout ",
      recommended: false,
    },
    {
      pricing_plan_id: "sasd-we43-sda3-asd-3",
      amount_bill: "200",
      amount_bill_unit: "mth",
      title: "6 months bill coverage",
      description: "<b>up to 3 mth</b> max payout ",
      recommended: true,
    },
  ],
  prin_msisdn: "60174311600",
  selected_msisdn: "60174311665",
};

let apiDummyData = {
  order_id: "ASDAS-ASDAS3asda-34sa-34waddfd",
  account_no: "549874",
  average_bill: "31055",
  average_bill_unit: "mth",
  active_policy: false,
  plans: [
    {
      pricing_plan_id: "asd-we43-sda3-aa33sd-4",
      amount_bill: "300",
      amount_bill_unit: "mth",
      title: "6 months bill coverage",
      description: "<b>up to 3 mth</b> max payout ",
      recommended: false,
    },
    {
      pricing_plan_id: "sasd-we43-sda3-asd-3",
      amount_bill: "200",
      amount_bill_unit: "mth",
      title: "6 months bill coverage",
      description: "<b>up to 3 mth</b> max payout ",
      recommended: true,
    },
  ],
  msisdn_list: [
    {
      msisdn: "60174311600",
      prin_ind: true,
    },
    {
      msisdn: "60174311601",
      prin_ind: false,
    },
  ],
};

const formatPlanData = (plans, firstTime) => {
  plans.map((ele) => {
    console.log(ele,"eleeeeplnnnn")
    if(ele.amount_bill=="1.00"){
      ele.header = "Plan 100";
    }else if(ele.amount_bill=="2.00"){
      ele.header = "Plan 200";
    }else if(ele.amount_bill=="3.00"){
      ele.header = "Plan 300";
    }
    ele.id = ele.pricing_plan_id;
    if (firstTime && ele.recommended) {
      ele.selected = true;
    } else {
      ele.selected = false;
    }
  });
  return plans;
};

let dialogData = {
  header: "Auto-renewal confirmation",
  text: "This plan will be automatically renewed every 6 months unless it's cancelled by you",
  sucessBtnText: "OK",
};

let dialogDataCloseRenew = {
  header: "Are you sure you want to opt-out of auto-renewal?",
  text: "Your plan will not be auto-renewed at the end of the coverage period.",
  sucessBtnText: "OK",
};

const label = { inputProps: { "aria-label": "Switch demo" } };
let checked = true;


const PlanSelection = () => {
  const [isAutoRenewChecked, setIsAutoRenewChecked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [autoRenewClosePopupOpen, setAutoRenewClosePopupOpen] = useState(false);
  const [plansList, setPlansList] = useState({});
  const [msisdn, setMsisdn] = useState([]);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  
const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  // width:70,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      content: isAutoRenewChecked?'"YES"':`""`,
      color: "white",
      fontSize:"small",
      // backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
      //   theme.palette.getContrastText(theme.palette.primary.main)
      // )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 16,
      top:19,
      // color:"#3e4a56",
      fontFamily: "Maxis",
      // fontSize: 12
      // text:""
    },
    "&:after": {
      content: !isAutoRenewChecked ? '"NO"' : `""`,
      color: "white",
      // backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
      //   theme.palette.getContrastText(theme.palette.primary.main)
      // )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 22,
      top:18,
      fontFamily: "Maxis",
      // fontSize: 14
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const Android12SwitchTick = styled(Switch)(({ theme }) => ({
  padding: 7,
  '& .MuiSwitch-track': {
    opacity:1,
    backgroundColor: "#8d8e97",
    borderRadius: 13,
    '&:before, &:after': {
      // content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,

    },
    '&:before': {
      // backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
      //   theme.palette.getContrastText(theme.palette.primary.main),
      // )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
      content:  
      // "'YES'", 
      isAutoRenewChecked ? "'YES'":"''",
      top:19,
      color:"#fff",
      fontFamily: "Maxis",
  fontSize: 14,
  fontWeight: "bold",
  letterSpacing: 1.5
         
      
    },
    '&:after': {
      // backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
      //   theme.palette.getContrastText(theme.palette.primary.main),
      // )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 22,
      top:19,
      content:
      // "'NO'",
      isAutoRenewChecked ? "''":"'NO'",
      color:"#fff",
      fontFamily: "Maxis",
  fontSize: 14,
  fontWeight: "bold",
  letterSpacing: 1.5

    },
  },
  
  '& .MuiSwitch-switchBase': {
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      right:2,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#8d8e97' : '#3e4a56',
        opacity: 1,
        border: 0,
      },
      // '&.Mui-disabled + .MuiSwitch-track': {
      //   opacity: 0.5,
      // },
    },
  },

  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));


  useEffect(() => {
    // getAll();
    getPlansData();
    // redirection(navigate);
    console.log("callinggg");

    // ReactGA.pageview(window.location.pathname + window.location.search);

    //formation of cards Data
    // let plansData=apiDummyData;
    // let plans = plansData.plans;
    // plans = formatPlanData(plans, "first");
    // setPlansList(plansData);
  }, []);

  const getPlansData = async () => {
    setLoading(true);
    // let order_id= sessionStorage.getItem('order_id');
    // let account_no= sessionStorage.getItem('account_no');
    // let msisdn= sessionStorage.getItem('msisdn');

    let order_id = secureLocalStorage.getItem("order_id");
    let account_no = secureLocalStorage.getItem("account_no");
    let msisdn = secureLocalStorage.getItem("msisdn");

    let authToken=secureLocalStorage.getItem("authToken")


    let params = {
      order_id: order_id ? order_id : "",
      account_no: account_no ? account_no : "",
      msisdn: msisdn ? msisdn : "",
    };
    try {
      let resp = await POST(GET_PLANS_DATA, params);
      setLoading(false);
      console.log(resp.data, "mmrespp plasdataaa");
      if(resp?.data?.status){
        if(resp?.data?.policyStatus=="Y"){
          // navigate("/alreadySubscribed")
          navigate(`/?token=${authToken}`);
        }else{
      let plansData = resp.data;
      let plans = plansData.plans;
      plans = formatPlanData(plans, "first");
      console.log(plansData, "plasdataaa===");
      plansData = getMsisdns(plansData);
      // remove after api is working
      setPlansList(plansData);

      //need to add below if we need to pass princ msisdn
      // let princMsisdn=plansData.prin_msisdn ? plansData.prin_msisdn : plansData.selected_msisdn;
      // secureLocalStorage.setItem("princMsisdn", msisdn);
      }
      }else{
        toast.error("Error - "+resp?.data?.reason);
      }

    } catch (err) {
      setLoading(false);
      toast.error(err.message + "Error!");
      console.log(err, "catcherrrr");
    }
  };

  // const getAll = async () => {
  //   let data = await GET(GET_PLAN_DETAILS);
  //   console.log(data, "dataaa000");
  // };

  const getMsisdns = (data) => {
    let msisdns = data.msisdn_list;
    let arr = [];
    if (msisdns && msisdns?.length) {
      msisdns.map((ele) => {
        if (ele.prin_ind) {
          arr.push(ele.msisdn);
        }
      });
      data.msisdns = arr;
    }
    return data;
  };

  const confirnClick = () => {
    // setIsAutoRenewChecked(true);
    setDialogOpen(false);
    // eventTrack('plan-selection-page', 'autoRenew-on-click', 'planSelection');
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
    setIsAutoRenewChecked(false);
  };

  const confirnCloseAutoRenewClick = () => {
    setAutoRenewClosePopupOpen(false);
    // eventTrack('plan-selection-page', 'autoRenew-off-click', 'planSelection');
  };

  const closeAutoRenewClick = () => {
    setAutoRenewClosePopupOpen(false);
    setIsAutoRenewChecked(true);
  };

  const autoRenewClick = () => {
    if (!isAutoRenewChecked) {
      setDialogOpen(true);
    } else {
      setAutoRenewClosePopupOpen(true);
    }
    setIsAutoRenewChecked((isAutoRenewChecked) => !isAutoRenewChecked);
  };

  const cardClick = (id) => {
    let plansData = { ...plansList };
    let plans = plansList?.plans;
    plans.map((plan) => {
      if (plan.pricing_plan_id == id) {
        plan.selected = true;
      } else {
        plan.selected = false;
      }
    });
    setPlansList(plansData);
  };

  const buyNowClick = async () => {
    let apiResponse = await purchaseSubmissionApiCall();
    // console.log(typeof apiResponse.data.status,"apiResponse");
    if (apiResponse) {
      navigate("/Consent");
    } else {
      let message = "Plan selection Err! " + apiResponse.data.status;
      alert(message);
    }
    // eventTrack('plan-selection-page', 'buy-now-click', 'planSelection');
  };

  console.log(isAutoRenewChecked, "autoRenewClosePopupOpen");

  const purchaseSubmissionApiCall = async () => {
    // let order_id= sessionStorage.getItem('order_id');
    // let account_no= sessionStorage.getItem('account_no');
    // let msisdn= sessionStorage.getItem('msisdn');

    let order_id = secureLocalStorage.getItem("order_id");
    let account_no = secureLocalStorage.getItem("account_no");
    let msisdn = secureLocalStorage.getItem("msisdn");

    let selectedPlan = plansList?.plans?.filter((plan) => {
      if (plan.selected) return plan;
    });
    let pricing_plan_id = selectedPlan[0].pricing_plan_id;
    console.log(isAutoRenewChecked, "selectedPlanjjjjpp");

    secureLocalStorage.setItem("pricing_plan_id", pricing_plan_id);
    secureLocalStorage.setItem("auto_renew", isAutoRenewChecked?.toString());

    let params = {
      order_id,
      account_no,
      msisdn,
      pricing_plan_id: pricing_plan_id,
      auto_renew: isAutoRenewChecked ? "Y" : "N",
    };
    // let resp = await POST(PURCHASE_SUBMISSION, params);
    // return resp;

    return pricing_plan_id;
  };

  const editAccClick = () => {
    navigate("/AccountSelection");
  };

  console.log(plansList, "kkklnkj");
  return (
    <>
    {/* <a style={{marginBottom: "0.5em",marginTop: "0.5em",display: "block"}} 
    href="/AccountSelection"><BackButton styles={{"marginLeft":"11px"}}/></a> */}
    <div style={{height:"15px"}}></div>
    <div className="plan-selection-container">
      <Loader open={loading} />
      {/* <p style={{height:"2px"}}></p> */}
      <div className="bottom-card-container">
        <Grid container spacing={2}>
          <Grid item xs={7} sm={7}>
            <p className="select-acc-head">Selected account</p>
            <p className="acc-no-text">{plansList.account_no}</p>
            <p className="acc-ph-num">
              {/* +60 12 345 1123 */}
              {plansList.selected_msisdn}
            </p>
          </Grid>
          <Grid item xs={5} sm={5} style={{ textAlign: "end" }}>
            <IconButton onClick={editAccClick}>
              <EditOutlinedIcon className="edit-icon" fontSize="medium" />
            </IconButton>
            {plansList.average_bill == "NA" || !plansList.average_bill? (
                ""
              ) : (
                <>
            <p className="avg-bill-text">Average bill amount</p>
            <p>
                  <span className="top-card-amount">
                    RM {plansList.average_bill}
                  </span>{" "}
                  <span className="sub-text">
                    /{plansList.average_bill_unit}
                  </span>
                            </p>
                            </>
              )}
          </Grid>
        </Grid>
      </div>

      <p className="sub-head">
        {" "}
        <span className="serial-num">2</span>{" "}
        <span className="plan-selection-subhead">Plan selection</span>{" "}
      </p>
      <p className="sub-small-text">Select a preferred coverage plan.</p>
      {plansList.plans?.map((ele, i) => {
        return <PlanCard key={i} card={ele} cardClick={cardClick} />;
      })}
      <br />
      <p className="sub-head" style={{marginBottom:"10px"}}>
        {" "}
        <span className="serial-num">3</span>{" "}
        <span className="plan-selection-subhead">Auto-renewal.</span>{" "}
      </p>
      {/* <p className="sub-small-text">
        Lorem ipsum dolor sit amet. Et quod asperiores ut.
      </p> */}

      {/* <FormControlLabel
        control={ 
       <Switch
      classes={switchStyles}
       {...label} 
       onChange={()=>{setIsAutoRenewChecked(isAutoRenewChecked=>!isAutoRenewChecked)}}
         /> 
      }
     labelPlacement="start"
     label={isAutoRenewChecked ? "On" : "Off"}
     />  */}

      <div className="bottom-card-container">

      <Grid container spacing={2}>
          <Grid item xs={10} sm={10}>
       <span className="bottom-card-head">Opt-in for auto-renewal</span>
      </Grid>
      <Grid item xs={2} sm={2} style={{paddingLeft: "0",paddingTop:"10px"}}>
            {/* <FormControlLabel
              control={
                <Android12Switch
                  value={isAutoRenewChecked}
                  checked={isAutoRenewChecked}
                  onChange={autoRenewClick}
                />
              }
            /> */}

<FormControlLabel
        control={<Android12SwitchTick 
          value={isAutoRenewChecked}
                  checked={isAutoRenewChecked}
                  onChange={autoRenewClick} 
                  />}
        // label="Android 12"
      />

            </Grid>
</Grid>            


        <Grid container spacing={2}>
          <Grid item xs={10} sm={10}>
            <p style={{marginTop:"5px"}}>Auto-renew your plan for another 6 months after your current protection ends.</p>
          </Grid>
          <Grid item xs={2} sm={2}>
            {/* <FormControlLabel
              control={
                <Android12Switch
                  value={isAutoRenewChecked}
                  checked={isAutoRenewChecked}
                  onChange={autoRenewClick}
                />
              }
              // label="Android 12"
            /> */}
          </Grid>
        </Grid>
      </div>
      <br />

      {/* <Link to="/Consent"> */}
      <Button
        className="buy-now-btn"
        size="small"
        style={{ backgroundColor: "#3e4a56", borderRadius: "1em" }}
        onClick={buyNowClick}
      >
        <span className="buy-now-btn-text">CONFIRM</span>
      </Button>
      {/* </Link> */}

      <div className="bottom-logo">
        <p>
          <span className="powered-by-text">Powered by</span>
          <img
            src={AiaLogo}
            alt="default-logo"
            className="aia-logo"
            height={24}
            width={24}
          />
        </p>
      </div>

      <DialogeBox
        data={dialogData}
        open={dialogOpen}
        confirnClick={confirnClick}
        handleClose={handleDialogClose}
      />
      <DialogeBox
        data={dialogDataCloseRenew}
        open={autoRenewClosePopupOpen}
        confirnClick={confirnCloseAutoRenewClick}
        handleClose={closeAutoRenewClick}
      />
    </div>
    </>
  );
};

export default PlanSelection;
