import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import Axios from "./axios";


export const GET = (url) => {
  return Axios.get(url);
};

export const GET_ONE = (id) => {
  return Axios.get(`/users/${id}`);
};

export const POST = async(url, data) => {
  let token = secureLocalStorage.getItem("authToken");
  console.log(token,"token postttttt");
  axios.defaults.headers.common["X-API-AUTH-TOKEN-VALUE"] = token?token:"";

  return Axios.post(url, data);
};

export const UPDATE = (id, data) => {
  return Axios.put(`/users/${id}`, data);
};

export const REMOVE_ONE = (id) => {
  return Axios.delete(`/users/${id}`);
};

export const REMOVE = () => {
  return Axios.delete(`/users`);
};
