import React from "react";
import  secureLocalStorage  from  "react-secure-storage";
import './accountSelection.css';
import { useState, useEffect } from 'react';
import { Button } from "@mui/material";

const AccountList = ({item}) => {
console.log(item,"itemdissssss")
    useEffect(()=>{
    },[])

    return (
      <div>
            <div className="checklist-column-disable" >
            <div className="checkbox">
                <span
                className="radio-custom-disable"
                />
            </div>
            <div className="accNum-row">
                <div className="accNum" style={{color:"#8e8f98"}} >{item.account_no}</div>
                <div className="telNum">{item.msisdn_list.map((msisdn) => msisdn.msisdn).join(', ')}</div>
            </div>

            <div className="protected-btn-container">
<Button id="protected-btn" variant="contained" size="small">PROTECTED</Button>
            </div>
            
            </div>  
            </div>
    );
  };
  

export default AccountList;


