import React from "react";
import { Container, Box, Grid, Button } from '@mui/material';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { pink } from '@mui/material/colors';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Icon } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DefaultLogo from '../../images/default-tag-vertical.svg';
import InfiniteLogo from '../../images/infinite.svg';
import Logo from '../../images/maxis-logo.svg';
import UpgradeLogo from '../../images/upgrade-logo.svg';
import PayBillLogo from '../../images/paybill-logo.svg';
import GameingLogo from '../../images/gameing-logo.svg';
import MaxisTvLogo from '../../images/maxisTv-logo.svg';
import MoreLogo from '../../images/more-logo.svg';
import './home.css';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 2,
        top: 13,
        //   border: `2px solid red`,
        background: "red",
        padding: '0 4px',
    },
}));

const Home = () => {
    return (
        <div className="container">

            <div className="top-container">

                <div className="badge-bar">
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <IconButton aria-label="cart">
                                <StyledBadge badgeContent={1} sx={{ color: 'white' }}>
                                    <NotificationsNoneOutlinedIcon sx={{ color: '#40c706' }} fontSize='large' style={{ 'transform': 'rotate(20deg)' }} />
                                </StyledBadge>
                            </IconButton>
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={4}>
                            <img src={Logo} alt="logo" />
                        </Grid>
                    </Grid>

                </div>

                <Grid container spacing={2}>
                    {/* <Grid item xs={1}></Grid> */}
                    <Grid item xs={6} >
                        <span className="top-text">Good morning </span><span className="top-text-bold">Maxis</span>
                    </Grid>
                    <Grid item xs={6}>
                        <span className="top-text">ALL SERVICES</span><ArrowRightAltOutlinedIcon sx={{ color: '#c4dad5' }} preserveAspectRatio='none' style={{ 'vertical-align': 'top', width: "1.5em" }} />
                    </Grid>
                    {/* <Grid item xs={1}></Grid> */}
                </Grid>


                <div className="top-card-parent">
                    <div className="top-card">
                        <div className="default-container">
                            {/* <span></span> */}
                            <img src={DefaultLogo} alt="default-logo" />
                        </div>
                        <IconButton className="icon-arrow-btn" style={{ backgroundColor: "lightgray" }}>
                            <ArrowForwardIosIcon className="arrow-icon" sx={{ color: '#40c706', fontSize: "15px", fontWeight: "600" }} />
                        </IconButton>

                        {/* <div className="card-content">
                        <div>
                         <img src={InfiniteLogo} alt="default-logo" />
                         </div>
                         <div>
                            <b>unlimited</b>
                         </div>
                    </div> */}
                        <Grid container spacing={2} className="card-content">
                            <Grid item xs={5} >
                                <div>
                                    <img src={InfiniteLogo} alt="default-logo" className="infinite-img" />
                                </div>
                            </Grid>
                            <Grid item xs={5} style={{ paddingLeft: "0px" }}>
                                <p className="card-head-text">Maxis Unlimited Postpaid 128</p>
                                <p className="mobile-text">6012 345 1123</p>
                                <Button variant="contained" size="small" className="manage-wifi-btn" style={{ backgroundColor: "#40c706", marginTop: '1em' }}>
                                    <span className="card-btn-text">MANAGE HOME WIFI</span>
                                </Button>
                            </Grid>
                        </Grid>

                    </div>


                    {/* <Grid container spacing={2}>
                        <Grid item xs={6} >
                            <div>
                                <img src={InfiniteLogo} alt="default-logo" />
                            </div>
                        </Grid>
                        <Grid item xs={6} >
                        </Grid>
                    </Grid> */}

                </div>


            </div>

            <div className="bottom-container">

                <div className="middle-card-parent">
                    <Box className="middle-card">
                        <div className="middle-card-flex">
                            <div className="middle-card-column">
                                <img src={UpgradeLogo} alt="default-logo" />
                                <p className="middle-card-column-text">Upgrade</p>
                            </div>
                            <div className="middle-card-column">
                                <img src={PayBillLogo} alt="default-logo" />
                                <p className="middle-card-column-text">Pay Bill</p>
                            </div>
                            <div className="middle-card-column">
                                <img src={GameingLogo} alt="default-logo" />
                                <p className="middle-card-column-text">Gaming</p>
                            </div>
                            <div className="middle-card-column">
                                <img src={MaxisTvLogo} alt="default-logo" />
                                <p className="middle-card-column-text">MaxisTV</p>
                            </div>
                            <div className="middle-card-column">
                                <img src={MoreLogo} alt="default-logo" />
                                <p className="middle-card-column-text">More</p>
                            </div>
                        </div>
                    </Box>
                </div>

                <div>
                    <span>Whats Hot</span>
                    </div>

            </div>

        </div>
    )
}

export default Home;