import React,{useEffect} from 'react';
// import ReactGA from 'react-ga';

const ProductDisclosur=()=>{

    useEffect(()=>{
        // ReactGA.pageview(window.location.pathname + window.location.search);
    },[])
    return(
        <div>
            <h1>ProductDisclosur Page</h1>
        </div>
    )
}

export default ProductDisclosur;
