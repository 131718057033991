import { useState } from "react";
import { Document, Page } from "react-pdf";
import { SizeMe } from "react-sizeme";

import "./commonStyles.css";

const Pdf = ({ url }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <SizeMe
      monitorHeight
      refreshRate={128}
      refreshMode={"debounce"}
      render={({ size }) => (
        <div className="pdf-container">
          {url && (
            <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <div key={`page_${index + 1}`} className="pdf-page">
                  <Page pageNumber={index + 1} width={size.width} scale={1.0} />
                  <div className="page-break" />
                </div>
              ))}
            </Document>
          )}
        </div>
      )}
    />
  );
};

export default Pdf;
