import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Home from "./components/home/home";
import Shop from "./components/shop/shop";
import AccountSelection from "./components/accountSelection/accountSelection";
import Consent from "./components/consent/consent";
// import Footer from "./components/layout/footer";
import LandingPage from './components/home/landingPage';
import PageNotFound from "./components/commonComponents/pageNotFound";
import PlanSelection from './components/planSelection/planSelection';
import Faq from './components/staticComponents/faq'
import ProductDisclosure from './components/staticComponents/productDisclosure'
import Timeout from './components/commonComponents/timeOut';
import UnAuth from "./components/commonComponents/unAuth";
import AlreadySub from "./components/commonComponents/alreadySubscribed";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';


function App() {
  console.log(process.env,"API_BASE_URL--");
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path="/home" element={<LandingPage />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/planSelection" element={<PlanSelection />} />
          <Route path="/accountSelection" element={<AccountSelection />} />
          <Route path="/consent" element={<Consent />} />
          <Route path="/faqs" element={<Faq />} />
          <Route path="/timeout" element={<Timeout />} />
          <Route path="/productDisclosure" element={<ProductDisclosure />} />
          <Route path="/unauthorized" element={<UnAuth />} />
          <Route path="/alreadySubscribed" element={<AlreadySub />} />
          

          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {/* <Footer/> */}
      </BrowserRouter>
      <ToastContainer/>
    </div>
  );
}

export default App;
