//for prod only
export const GET_PLAN_DETAILS="/policy-check";
export const VALIDATE_USER="/policy-check";
export const GET_ACC_LIST="/account-list";
export const GET_PLANS_DATA="/account-info";
export const POLICY_VIEW="/claim-submission";
export const PURCHASE_SUBMISSION="/purchase-submission";

// new urls  for dev only 
// export const GET_PLAN_DETAILS="/policy-check-v2";
// export const VALIDATE_USER="/policy-check-v2";
// export const GET_ACC_LIST="/account-list-v2";
// export const GET_PLANS_DATA="/account-info-v2";
// export const POLICY_VIEW="/claim-submission-v2";
// export const PURCHASE_SUBMISSION="/purchase-submission-v2";



