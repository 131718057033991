import React from "react";
import { useState, useEffect } from 'react';
import { Link, Router,useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import  secureLocalStorage  from  "react-secure-storage";
// import {eventTrack} from '../../commonFunctions/ga-event';
import './selection.css';
import './accountSelection.css';
import BackArrow from '../../images/backArrow.svg';
import NumberOne from '../../images/num11.svg';
import AiaLogo from '../../images/aia-logo.svg';
import Button from '@mui/material/Button';
import AccountListCard from "./accountListCard";
import { POST } from '../../services/commonServices';
import { GET_ACC_LIST } from '../../constents/apiEndPoints';
import Loader from '../commonComponents/loader';
// import {redirection} from "../../commonFunctions/timeoutRedirection";
import BackButton from "../commonComponents/backButton";

let dummyData={
    "order_id": "ASDAS-ASDAS3asda-34sa-34waddfd",
    "accounts": [{
        "account_no": "549874",
        "active_policy": true,
        "msisdn_list": [{
            "msisdn": "60174311600",
            "plan_name": "Hot link post 60 with home internet home internet "
        }, {
            "msisdn": "60174311601",
            "plan_name": ""
        }]
    }, {
        "account_no": "549875",
        "active_policy": true,
        "msisdn_list": [{
            "msisdn": "60174311603",
            "plan_name": ""
        }, {
            "msisdn": "60174311604",
            "plan_name": ""
        }, {
            "msisdn": "60174311605",
            "plan_name": ""
        }, {
            "msisdn": "60174311606",
            "plan_name": ""
        }, {
            "msisdn": "60174311604",
            "plan_name": ""
        }]
    },
    {
        "account_no": "549876",
        "active_policy": false,
        "msisdn_list": [{
            "msisdn": "60174322600",
            "plan_name": "Hot link post 60 with home internet home internet "
        }, {
            "msisdn": "60174322601",
            "plan_name": ""
        }]
    }]
}

// const BackButton = () => {
//     return (<div className="-BackButton">
//         <div className="back-arrow">
//             <img src={BackArrow} class="back-arrow" alt="" />
//         </div>
//         <div className="-Label">
//             <span className="-Label">Back</span>
//         </div>
//     </div>)
// }

const AccSelectionDesc = () => {
    return (
        <div className="-Description">
            <div className="number-row">
                {/* <img src={NumberOne} class="number-icon" alt=""></img> */}
                <span className="number">1</span>
            </div>
            <div className="desc-row">
                <div className="acc-selection-text">
                    <span class="Account-selection"> Account selection</span><br></br>
                </div>
                <div className="select-text" style={{textAlign:"start"}}>
                    <p class="Select-an-account-to">Please select an account to sign up for bill protection.</p>
                </div>
            </div>
        </div>
    )
}

const PoweredBy = () => {
    return (
        <div className='bottom-logo'>
            <p ><span className='powered-by-text'>Underwritten by</span>
            <img src={AiaLogo} alt="default-logo" className='aia-logo' height={24} width={24} />
            </p> 
        </div>
    )
}

const Selection = () => {

    const [accList, setAccList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedAccNo, setSelectedAccNo] = useState("");
    const [selectedMsisdn, setSelectedMsisdn] = useState("");

    let navigate = useNavigate();

    useEffect(() => {
        getAccList();
        // redirection(navigate);
    }, [])

    const getAccList = async () => {
        try{
        setLoading(true);
        const urlParams = new URLSearchParams(window.location.search);
        // const order_id = sessionStorage.getItem('order_id');
        let order_id = secureLocalStorage.getItem("order_id");
        console.log(order_id,"vallstoree");
        urlParams.get('order_id');
        // sample = order_id : "62fe37db-acaa-4d4f-ab39-a062cfd2f9fd"
        // session storage = sessionStorage.getItem('order_id'); ---> use this

        let params = {
            order_id : order_id
            // "62fe37db-acaa-4d4f-ab39-a062cfd2f9fd"
        };

        let resp = await POST(GET_ACC_LIST, params);
        console.log(resp?.data, "acccclist daaataaaa");
        if(resp?.data?.status){
            let accData=resp.data?.accounts;
            console.log(accData,"accDatagggk")
            let notHavingActivePolicy=[];
            let activePolicyArr=[];
            accData.map(ele=>{
                if(!ele.active_policy){
                    notHavingActivePolicy.push(ele);
                }else{
                    activePolicyArr.push(ele);
                }
            })
            console.log(notHavingActivePolicy,"accArrttttt")
            let accArr=[...activePolicyArr,...notHavingActivePolicy]
            setAccList(accArr);
        }else{
            toast.error("Error- "+resp?.data?.reason ? resp?.data?.reason :"-");
        }

        setLoading(false);
    }catch(err){
        setLoading(false);
        // console.log(err,"errresppmlkm")
        toast.error(err.message+"Error!");
    }

    };    

    //dummy data
    // const getAccNum = [
    //     {order_id:"aaa7f69d-5a60-4723-8b5b-f0379f68818d",
    //     accounts:[
    //         {account_no:"549874",active_policy:true,msisdn_list:[{msisdn:"60174311600",prin_ind:true},{msisdn:"60174311601",prin_ind:false}]},
    //         {account_no:"549875",active_policy:false,msisdn_list:[{msisdn:"60174311603",prin_ind:true},{msisdn:"60174311604",prin_ind:false}]}]}        
    // ];

    // let account_no = secureLocalStorage.getItem("account_no");
    // let msisdn = secureLocalStorage.getItem("msisdn");

    // console.log(account_no,msisdn,"account_nollll")

    const accNoAndMsisdnChange=(accNo,msisdn)=>{
        console.log(accNo,msisdn,"accNooooo");
        setSelectedAccNo(accNo);
        setSelectedMsisdn(msisdn);
    }

    return (
        <div>
            <Loader open={loading} />
            <div style={{height:"15px"}}></div>
            {/* <a href="/"><BackButton styles={{"marginLeft":"11px"}}/></a> */}
            <AccSelectionDesc />

            <div >
                <div>
                <AccountListCard data={accList} accNoAndMsisdnChange={accNoAndMsisdnChange} />
                </div>
            </div>

            {selectedAccNo && selectedMsisdn ?
            <Link to={"/PlanSelection"}>
                <Button className='continue-btn' size='small' style={{ backgroundColor: "#3e4a56", borderRadius: '1em', margin: "0 1em"}} disabled={!selectedAccNo && !selectedMsisdn}>
                <span className='continue-btn-text'>Continue</span>
                </Button>
            </Link>
            :
            <Button className='continue-btn' size='small' style={{ backgroundColor: "#c2c3c4", borderRadius: '1em', margin: "0 1em"}} >
                <span className='continue-btn-text'>Continue</span>
                </Button>
}

            <PoweredBy />
        </div>
    )
}

export default Selection;