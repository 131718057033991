import React, { useState, useEffect } from 'react';
import { Container, Divider, Grid, Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import secureLocalStorage from "react-secure-storage";
// import ReactGA from 'react-ga';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
// import Logo from '../../images/maxisLogoLatest.svg';
import Logo from "../../images/bannerImage.jpg";
import MMALogo from "../../images/bannerMMA.png";
import AiaLogo from '../../images/aia-logo.svg';
import AIABanner from "../../images/aia-insureTech-banner.png";
import MaxisBanner from "../../images/maxis-insureTech-banner.png";
import Accordian from '../commonComponents/accordian';
import Loader from '../commonComponents/loader';
import { POST } from '../../services/commonServices';
// import {redirection} from "../../commonFunctions/timeoutRedirection";
import { VALIDATE_USER, POLICY_VIEW } from '../../constents/apiEndPoints';
// import {eventTrack} from '../../commonFunctions/ga-event';
// import 'react-toastify/dist/ReactToastify.css';
import productDis from "../consent/Maxis-AIA-Bill-Protection-PDS.pdf";
import policy from "../consent/Maxis-AIA-Bill-Protection-Policy.pdf";
import Model from '../commonComponents/model';


// console.log(window.location,"loctbbbbb")


let tokengot = secureLocalStorage.getItem("token");
console.log(tokengot, "tokengot00000")

const LandingPage = (props) => {


    // const [isHavingPlans, setIsHavingPlans] = useState(true);
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [isOpenContactUs, setIsOpenContactUs] = useState(false);

    const [pageUrl, setpPageUrl] = useState("");
    const [modelOpen, setModelOpen] = useState(false);




    const urlParams2 = new URLSearchParams(window.location.search);
    const token = urlParams2.get('token');
    console.log(token, "propppssssslanding");
    if (token) {
        console.log(token, "ifffffffff");
        secureLocalStorage.setItem("authToken", token);
    }

    // const [orderId,setOrderId] = useState({});
    // const [token,setToken] = useState("");

    // const headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    // headers.append('Access-Control-Allow-Origin', '*');

    // async function postData(url = '', data = {}) {
    //     // Default options are marked with *
    //     const response = await fetch(url, {
    //       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //       mode: 'cors', // no-cors, *cors, same-origin
    //       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //       credentials: 'same-origin', // include, *same-origin, omit
    //       headers: headers,
    //       redirect: 'follow', // manual, *follow, error
    //       referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //       body: JSON.stringify(data) // body data type must match "Content-Type" header
    //     });
    //     return response.json(); // parses JSON response into native JavaScript objects
    //   }


    let navigate = useNavigate();

    useEffect(() => {
        validateUser();
        // redirection(navigate)

        // setTimeout(() => {
        //     setLoading(false);
        // }, 2000);
        // console.log(window.location.pathname + window.location.search,"pathhhh")
        // ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])



    const validateUser = async () => {
        try {
            setLoading(true);
            //  console.log(token,"loccc");
            //  setToken(token);
            let params = {
                token
            }

            // let res;
            let res = await POST(
                VALIDATE_USER
                // "https://r812lgtpj7.execute-api.ap-southeast-1.amazonaws.com/prod/policy-check-v2"
                , params);

            console.log(res, "res?.dataaaaaaaaaa")


            // let resp = await axios.post("https://bgicjlyuze.execute-api.ap-southeast-1.amazonaws.com/insurTech/poc", params);

            // toast(JSON. stringify(res)+" ress");
            // console.log(res,"ressd")

            //         postData('https://bgicjlyuze.execute-api.ap-southeast-1.amazonaws.com/insurTech/policy-check', params)
            //   .then((data) => {
            //     console.log(data,"dataaaaa"); // JSON data parsed by `data.json()` call
            //     toast(JSON. stringify(data)+" ress");
            //         setOrderId(data);
            //         setLoading(false);
            //         setUserData(data); 
            //         secureLocalStorage.setItem("order_id", data?.order_id); 
            // }).catch(err=>{

            //     setLoading(false);
            //     toast.error(err.message+" catch Error!");
            // })

            // setOrderId(res?.data);

            setLoading(false);
            if (res?.data?.status) {
                setUserData(res?.data);
                secureLocalStorage.setItem("order_id", res?.data?.order_id);
                navigate("/");
            }else{
                toast.error("Error - "+res?.data?.reason);
            }



            // remove once API working
            // sessionStorage.setItem('order_id',res.data.order_id);
            // sessionStorage.setItem('order_id','776ca29c-2a1a-4c13-90e8-26cb8482bf5e')
            // toast("OrderID - "+res.data.order_id);
            // console.log(res.data, "mmrespp");
        } catch (err) {
            console.log(err, "errrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
            setLoading(false);
            toast.error(err?.message + " Error!");
        }
    }

    const buyNowClick = () => {
        console.log("buynow clickedd")
        // eventTrack('landing-page', 'buy-now-click', 'home');
    }

    const myPolicyClick = async () => {
        try {
            setLoading(true);
            console.log(userData, "myPolicyClick")
            let params = {
                order_id: userData.order_id
            }
            let res = await POST(POLICY_VIEW, params);
            console.log(res.data, "ressss===");
            setLoading(false);
            if (res.data?.status) {
                let url = res.data?.redirectionURL;
                window.location.replace(url);
            } else {
                setLoading(false);
                toast.error(res.data?.reason + " Error!");
            }
        } catch (err) {
            setLoading(false);
            toast.error(err?.message + " Error!");
        }
    }

    const handleOpen = (type) => {
        // setPageType(type);
        setModelOpen(true);
    }
    const handleClose = () => {
        setModelOpen(false);
    }

    // console.log(orderId.active_policy,"orderId.active_policy")

    return (
        <div>
            <Loader open={loading} />
            {userData.channel === "HLFLEX" || userData.channel === "HFA" ?
                <img src={AIABanner} alt="default-logo"
                    style={{ width: "100%" }}
                />
                :
                (userData.channel === "MMA" || userData.channel === "MYAPPS" ?
                <img src={MaxisBanner} alt="default-logo"
                    style={{ width: "100%" }}
                />
            :
            <Skeleton style={{height: "200px"}} /> )
            }

            {/* </div> */}
            <div className='margin-container'>
                <p className='bill-protextion-head'>Bill Protection by AIA</p>
                <p className='bill-protextion-text'>Cover your Maxis bill for 3 months in the event of hospitalization and job loss. The policy period is of a duration of 6 months.</p>

                <Divider style={{ marginTop: "1em", marginBottom: "1em" }} />

                {userData?.active_policy ?
                    <>
                        <div className='manage-policy-tab'>
                            <p className='manage-policy-head'>Manage your policy</p>

                            <div className='mypolicy-tab' onClick={myPolicyClick}>
                                <p className='policy-head'>My policy</p>
                                <div className='row-flex'>
                                    {/* <div className='col-md-4 col-sm-4 col-xs-4'> */}

                                    <p className='policy-text'>Manage and check status of your policy here.</p>
                                    {/* </div> */}
                                    {/* <div className='col-md-4 col-sm-4 col-xs-4'> */}
                                    <IconButton style={{ height: "26px" }}>
                                        <KeyboardArrowRightIcon fontSize='large' />
                                    </IconButton>
                                    {/* </div> */}
                                </div>

                            </div>

                            {/* <div className='mypolicy-tab'>
                                <p className='policy-head'>My claims</p>
                                <p className='policy-text'>Submit and check claims status here.</p>
                                <IconButton className="icon-arrow-btn-policy">
                                    <KeyboardArrowRightIcon fontSize='large' />
                                </IconButton>
                            </div> */}

                        </div>
                        <Divider style={{ marginTop: "2em" }} />
                    </>
                    : ""}



                <p className='coverage-plan'>Coverage plan</p>
                <div className='coverage-plan-container'>

                    {/* <Grid container spacing={2} className="card-content">
                    <Grid item xs={4} ></Grid>
                </Grid> */}

                    <div className='top-tab-top'>
                        <div className='top-left-tab'>
                            <span className='left-tab-text-top'></span>
                        </div>
                        <div className='small-tab-top-first'>Plan 300</div>
                        <div className='small-tab-top'>Plan 200</div>
                        <div className='small-tab-top-last'>Plan 100</div>
                    </div>

                    <div className='top-tab'>
                        <div className='top-left-tab-data'>
                            <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                <p className='left-tab-text'>Amount of Benefit per Month</p>
                                <p className='left-tab-sub-text' style={{ marginTop: "0px" }}>(Payable for 3 months)</p>
                            </div>
                        </div>
                        <div className='small-tab' style={{ marginLeft: "1px" }}>RM300</div>
                        <div className='small-tab'>RM200</div>
                        <div className='small-tab-top-last-data'>RM100</div>
                    </div>

                    <div className='bottom-tab'>
                        <div className='bottom-left-tab'>
                            <div style={{ padding: "0px 10px" }}>
                                <p className='left-tab-text'>Monthly premium</p>
                                <p className='left-tab-text'>(Including SST)</p>
                            </div>

                        </div>
                        <div className='small-tab' style={{ marginLeft: "1px" }}>RM3</div>
                        <div className='small-tab'>RM2</div>
                        <div className='small-tab-bottom-last'>RM1</div>
                    </div>
                </div>
                {userData?.order_id ?
                    <Link to={"AccountSelection"}>
                        <Button className='buy-now-btn' size='small' style={{ backgroundColor: userData?.order_id ? "#3e4a56" : "#c2c3c4", borderRadius: '1em' }} onClick={buyNowClick}>
                            <span className='buy-now-btn-text'>Buy Now</span>
                        </Button>
                    </Link>
                    :
                    <Button className='buy-now-btn' size='small' style={{ backgroundColor: "#c2c3c4", borderRadius: '1em' }}>
                        <span className='buy-now-btn-text'>Buy Now</span>
                    </Button>
                }



                <h3 className='need-help-text'>NEED HELP?</h3>
            </div>

            <div className='bottom-container'>
                <Link to={"faqs"}>
                    <div className='faq-tab'>
                        <p className='faq-head'>FAQ</p>
                        <IconButton className="icon-arrow-btn-policy">
                            <KeyboardArrowRightIcon fontSize='large' />
                        </IconButton>
                    </div>
                </Link>
                <hr style={{ margin: "0px" }} />
                {/* <Link to={"productDisclosure"}> */}
                <div className='faq-tab' onClick={() => { setpPageUrl(productDis); handleOpen() }}>
                    <p className='faq-head'>Product Disclosure Sheet</p>
                    <IconButton className="icon-arrow-btn-policy">
                        <KeyboardArrowRightIcon fontSize='large' className='icon-arrow-home' />
                    </IconButton>
                </div>
                {/* </Link> */}
                <hr style={{ margin: "0px" }} />
                {/* <Link to={"productDisclosure"}> */}
                <div className='faq-tab' onClick={() => { setpPageUrl(policy); handleOpen() }}>
                    <p className='faq-head'>Policy Wording</p>
                    <IconButton className="icon-arrow-btn-policy">
                        <KeyboardArrowRightIcon fontSize='large' className='icon-arrow-home' />
                    </IconButton>
                </div>
                {/* </Link> */}
                <hr style={{ margin: "0px" }} />

                <div className='faq-tab' onClick={() => setIsOpenContactUs(isOpenContactUs => !isOpenContactUs)}>
                    <p className='faq-head'>Contact us</p>
                    {!isOpenContactUs ? <IconButton className="icon-arrow-btn-policy">
                        <KeyboardArrowRightIcon fontSize='large' className='icon-arrow-home' />
                    </IconButton>
                        :
                        <IconButton className="icon-arrow-btn-policy">
                            <KeyboardArrowDownIcon fontSize='large' className='icon-arrow-home' />
                        </IconButton>
                    }

                </div>
                {isOpenContactUs ? <div className='contact-us-tab'>
                    {/* <p>If you need further assistance, please reach out to us. </p> */}
                    <hr />

                    <div className='container-inner'>
                    <span className='contactUsKeys'>WhatsApp</span>
                    {/* <span> */}
                    {/* <a href="whatsapp://send?text=WHATEVER_LINK_OR_TEXT_YOU_WANT_TO_SEND" title="Share on whatsapp"> */}
                    <a href="//api.whatsapp.com/send?phone=+60196299660&text=Hi">
                    <span className='contactUsValues' >+60 19629 9660</span>
                    <IconButton >
                        <KeyboardArrowRightIcon fontSize='large' className='icon-arrow-home' />
                    </IconButton>
                    </a>
                    {/* </span> */}
                    </div>

                    <hr />

                    
                    <div className='container-inner'>
                    <h3 className='contactUsKeys'>Email</h3>
                    <a href="mailto:my.aiaonline@aia.com?subject=&body=">
                    <span className='contactUsValues' >my.aiaonline@aia.com</span>
                    <IconButton >
                        <KeyboardArrowRightIcon fontSize='large' className='icon-arrow-home' />
                    </IconButton>
                    </a>
                    </div>

                    {/* <h3>Email</h3>
                    <p>my.aiaonline@aia.com</p> */}
                </div> : ""}
                <hr style={{ margin: "0px" }} />



                {/* <div className='bottom-accordian-container'>
                    <Accordian header={"Contact us"} text={"text"} />
                </div> */}

            </div>


            {/* <Grid container spacing={2} mt={5} className="card-content">
                    <Grid item xs={6} > */}
            <div className='bottom-logo'>
                <p ><span className='powered-by-text'>Underwritten by</span>
                    <img src={AiaLogo} alt="default-logo" className='aia-logo' height={24} width={24} />
                </p>
            </div>
            {/* </Grid>
                </Grid> */}

            <Model url={pageUrl} open={modelOpen} handleOpen={handleOpen} handleClose={handleClose} />



        </div>
    )
}

export default LandingPage;