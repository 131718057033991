import React from "react";
import { Container, Box, Grid, Button } from '@mui/material';
import MaxisProtectLogo from '../../images/maxis-protect-logo-black.svg';
import './planSelection.css';
let border={border:'solid 2px #3e4a56'};

const PlanCard = ({card,cardClick}) => {

  // const cardClick=(e)=>{
  //   console.log(e.target.id,"eeeeeeeeeee")
  // }
  
console.log(card,"carddataaaa")

let description=card.description;
if (description){ description= description.replace("RM ","RM&nbsp;") }

  return (
    <div>
    <div className="card-container"
     style={card.selected?border:{}}
     onClick={()=>cardClick(card.pricing_plan_id)}
     >
      <span className="card-head">{card.header}</span>

      <Grid container spacing={2} style={{marginTop:"0.1em",marginLeft:"-10px"}}>
        <Grid item xs={2} sm={2} style={{paddingTop:"0px"}}>
            <img src={MaxisProtectLogo} height={30} width={30} />
        </Grid>
        <Grid item xs={6} sm={6} style={{padding:"0px"}}>
            <p className="card-bill-info">{card.title}</p>
            {/* <p className="card-month-amount">{card.description}</p> */}
            <div className="content" dangerouslySetInnerHTML={{__html: description}}></div>
        </Grid>
        <Grid item xs={4} sm={4} style={{padding:"0px"}}>
           <p className="bill-amount"> <span className="card-amount">{card.amount_bill}</span> <span className="sub-text-card">/{card.amount_bill_unit}</span></p>
        </Grid>

      </Grid>
{card.recommended? <span className="recomended-tag">RECOMMENDED</span> : ""}


    </div>

    </div>
  );
};

export default PlanCard;
