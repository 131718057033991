import React from "react";


const AlreadySub=()=>{

    return(
        <div style={{background:"#e6e0df",heigh:"50em",marginTop: "0em",paddingTop: "30%",height:"100vh"}}>
            <h2>Already Subscribed</h2>
            <b>Please try again</b>
            <br/> <br/>
            {/* <img src="https://www.online-tech-tips.com/wp-content/uploads/2021/06/http-403.jpeg" height={200} width={300}></img> */}
        </div>
    )
}

export default AlreadySub;