import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import BackButton from "../commonComponents/backButton";
import Model from '../commonComponents/model';
import productDis from "../consent/Maxis-AIA-Bill-Protection-PDS.pdf";
import policy from "../consent/Maxis-AIA-Bill-Protection-Policy.pdf";
import "./staticComponent.css";

const nodes = {
  id: 1,
  name: "de Volksbank",
  children: [
    {
      id: 2,
      name: "dVb",
      desc:"hello examoppllll"
    },{
      id: 3,
      name: "dVb22",
      desc:"hello examoppllll222"
    }]
  }

export default function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState(false);
  const [mainExpanded, setMainExpanded] = React.useState(false);
  const [pageUrl, setPageUrl] = React.useState("");
  const [modelOpen, setModelOpen] = React.useState(false);
  const [pageType, setPageType] = React.useState("");

  

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleMainChange = (panel) => (event, isExpanded) => {
    setMainExpanded(isExpanded ? panel : false);
  };

  
const renderChildren = (children) => {
  return (
    <Accordion>
      {children.map((c) => (
        <>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} key={c.id}>
            <Typography key={c.id}>{c.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography id='ans-text'>{c.desc}</Typography>
        </AccordionDetails>
        </>
      ))}
    </Accordion>
  );
};

const renderNode = (node) => {
  return (
    <Accordion key={node.id}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} key={node.id}>
        <Typography>{node.name}</Typography>
      </AccordionSummary>
      <AccordionDetails key={node.id}>
        {renderChildren(node.children)}
      </AccordionDetails>
    </Accordion>
  );
};

console.log(expanded,"expandedffff")


const handleOpen=(type)=>{
  setPageType(type);
  setModelOpen(true);

}
const handleClose=()=>{
  setModelOpen(false);
}

  return (
    <div>
      
    {/* <a style={{marginBottom: "0.5em",marginTop: "0.5em",display: "block"}} 
    href="/"><BackButton /></a> */}

      <p id='header-top'>Frequently Asked Questions</p>

      {/* {renderNode(nodes)} */}


      <Accordion expanded={mainExpanded=='panel1'
        // expanded === 'panel1' || expanded=='panel11' || expanded=='panel12' || expanded=='panel13'|| expanded=='panel14'|| expanded=='panel15'|| expanded=='panel16'|| expanded=='panel17'|| expanded=='panel18'|| expanded=='panel19'
        } onChange={handleMainChange('panel1')}>
        <AccordionSummary
          expandIcon={
            mainExpanded=='panel1'
            // expanded=='panel1' || expanded=='panel11' || expanded=='panel12'|| expanded=='panel13'||expanded=='panel14'|| expanded=='panel15'|| expanded=='panel16'|| expanded=='panel17'||expanded=='panel18'|| expanded=='panel19'
            ?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography id='qtn-text' >PRODUCT BENEFITS & PREMIUM</Typography>
        </AccordionSummary>
        <AccordionDetails>

        <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary
          expandIcon={expanded=='panel11'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography id='qtn-text'>What is Bill Protection?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          Bill Protection is an affordable personal accident insurance that provides protection to cover your Bill due to your inability to work if you are hospitalised for at least 3 consecutive days caused by accident or infectious diseases. If you are employed, this product also covers your Bill in the event of involuntary loss of employment.
          <br/><br/>
<b>Note:</b> <br/>
    • “Bill” refers to your monthly payment for charges incurred for products and services under your Account, registered with Maxis Broadband Sdn. Bhd. <br/>
    • “Account" refers to your Maxis or Hotlink account registered with Maxis Broadband Sdn. Bhd. <br/>
    • This product is underwritten by AIA General Berhad and can only be purchased through Maxis or Hotlink Application.<br/>

          </Typography>
        </AccordionDetails>
      </Accordion>

      
      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary
          expandIcon={expanded=='panel12'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>What benefits are provided under Bill Protection? 
</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          Bill Protection offers you the following benefits with 3 different Amount of Benefit per month available for selection:
  <table id='table-text'>
  <tr>
    <th style={{backgroundColor:'#ba0b1a',color:'white'}} rowSpan={"2"}>Section</th>
    <th style={{backgroundColor:'#ba0b1a',color:'white'}} rowSpan={"2"}>Benefits</th>
    <th style={{backgroundColor:'#ba0b1a',color:'white'}}  colspan="3">Amount of benefit per month (RM)</th>
  </tr>
  <tr>
  {/* <th style={{backgroundColor:'#ba0b1a',color:'white'}}></th> */}
  {/* <th style={{backgroundColor:'#ba0b1a',color:'white'}}></th> */}
  <th style={{backgroundColor:'#ba0b1a',color:'white'}}>Plan 100</th>
  <th style={{backgroundColor:'#ba0b1a',color:'white'}}>Plan 200</th>
  <th style={{backgroundColor:'#ba0b1a',color:'white'}}>Plan 300</th>

  </tr>
  <tr>
    <td>1.</td>
    <td style={{textAlign:"start"}}>Hospitalisation for at least 3 consecutive days caused by <br/>
   &nbsp; (A) Accident; or <br/>
   &nbsp; (B) Infectious Disease benefit
    </td>
    <td rowspan="2">100</td>
    <td rowspan="2">200</td>
    <td rowspan="2">300</td>
    
  </tr>
  <tr>
    <td>2.</td>
    <td style={{textAlign:"start"}}>Involuntary loss of employment benefit
</td>

   
  </tr>
 
 
</table><br/>
<h7>Note:</h7>
<ul style={{lineHeight:'1.5'}}>
<li >Certificate period is 6 months.</li>
<li>The benefit payable is three times, the Amount of Benefit per month, as shown in the Schedule of Benefits in one lump sum and it is fixed regardless of your actual incurred Bill amount.</li>
<li>Claim is only limited to either 1 event of hospitalisation or 1 event of involuntary loss of employment.</li>
<li>Kindly refer to policy contract for more information on the benefits provided</li>
</ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      
      <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
        <AccordionSummary
          expandIcon={expanded=='panel13'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>What infectious diseases are covered?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          Covered infectious diseases include:
        
          <p>(a) Anthrax Infection;</p>
<p>(b) Avian Influenza or “Bird Flu”; </p>
<p>(c) Chikungunya Fever; </p>
<p>(d) Cholera; </p>
<p>(e) COVID-19;</p>
<p>(f) Dengue Fever;</p>
<p>(g) Ebola; </p>
<p>(h) Hand, Foot and Mouth Disease (HFMD);</p> 
<p>(i) Japanese Encephalitis; </p>
<p>(j) Legionnaires’ Disease; </p>
<p>(k) Malaria; </p>
<p>(l) Measles; </p>
<p>(m) Melioidosis or “Soil Disease”;</p>
<p>(n) Middle East Respiratory Syndrome (MERS-CoV); </p>
<p>(o) Mumps; </p>
<p>(p) Nipah Virus Encephalitis; </p>
<p>(q) Plague; </p>
<p>(r) Rabies; </p>
<p>(s) Rubella; </p>
<p>(t) Severe Acute Respiratory Syndrome (SARS);</p>
<p>(u) Tuberculosis; </p>
<p>(v) Variant Creutzfeldt-Jakob Disease (vCJD) or “Mad Cow Disease”;</p>
<p>(w) Yellow Fever; or</p>
<p>(x) Zika Virus.</p>
<p>Kindly note that there will be 14 days of waiting period from the issue date or commencement date of certificate of insurance, whichever is later, before you are eligible for hospitalisation due to infectious diseases benefit. Waiting period is only applied when you are first covered and shall not be applicable after the first 6 months of coverage. However, if there is a break in continuity of the insurance coverage after the first 6 months of coverage, the waitingperiod shall apply again.</p>
      
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
        <AccordionSummary
          expandIcon={expanded=='panel14'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography style={{textAlign:'start'}} id='qtn-text'>How much do I need to pay for Bill Protection?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          Monthly premium starts from as low as RM1, inclusive of fees/charges and applicable government tax. Kindly note that monthly premium varies depending on your selected amount of benefit per month. <br/><br/>
          <table id='table-text'>
  <tr>
    <th style={{backgroundColor:'#ba0b1a',color:'white'}}>Amount of Benefits per month(RM)</th>
    <th style={{backgroundColor:'#ba0b1a',color:'white'}}>Monthly Premium(RM)</th>
    
  </tr>
  <tr>
    <td>100</td>
    <td>1.00</td>
   
  
    
  </tr>
  <tr>
    <td>200</td>
    <td>2.00</td>

   
  </tr>
  <tr>
    <td>300</td>
    <td>3.00</td>

   
  </tr>
 
</table><br/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
        <AccordionSummary
          expandIcon={expanded=='panel15'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography style={{textAlign:'start'}} id='qtn-text'>How often do I need to make premium payment?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          You need to make monthly premium payment for a certificate period of 6 months. <br/><br/>

          </Typography>
        </AccordionDetails>
      </Accordion>  
      <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
        <AccordionSummary
          expandIcon={expanded=='panel16'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography style={{textAlign:'start'}} id='qtn-text'>How do I make premium payment?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          Your monthly premium will be charged to your Account as part of your Bill.  <br/><br/>

          </Typography>
        </AccordionDetails>
      </Accordion>    
      <Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
        <AccordionSummary
          expandIcon={expanded=='panel17'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography style={{textAlign:'start'}} id='qtn-text'>Is there any waiting period before I am eligible for the benefits?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
         <p>Eligibility for benefits will commence after a specific waiting period from the issue date or the commencement date of the certificate of insurance, whichever is later. Waiting period is applied only when you are first covered and shall not be applicable after the first 6 months of coverage. However, if there is a break in continuity of the insurance coverage after the first 6 months of coverage, the waiting period shall apply again. </p>   <br/>
         <table style={{width:'100%'}} id='table-text'>
  <tr>
    <th style={{backgroundColor:'#ba0b1a',color:'white'}}> Benefits</th>
    <th style={{backgroundColor:'#ba0b1a',color:'white'}}>Waiting Period</th>
    
  </tr>
  <tr>
    <td>Hospitalisation due to infectious diseases benefit</td>
    <td>14 days</td>
   
  
    
  </tr>
  <tr>
    <td>Involuntary loss of employment benefit
</td>
    <td>30 days</td>

   
  </tr>
  
 
</table><br/><br/>

  

          </Typography>
        </AccordionDetails>
      </Accordion>    
      <Accordion expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
        <AccordionSummary
          expandIcon={expanded=='panel18'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>What happens to my certificate if I don’t make premium payment or pay my Bill on time?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          If you do not pay your Bill on time, and your Account has been frozen or suspended and as a consequence we are not able to charge the monthly premium to your Account, you will be given 1 calendar month of grace period, starting from the date of which the monthly premium last successfully charged on your Account, to clear your payment. Your certificate will remain in force during this grace period. If any claim arises during the grace period, your claim will be subjected to deduction of any outstanding premium for the certificate period and only the balance of claim amount is payable.
          If you are unable to pay premium due within the grace period, your certificate will be terminated. <br/><br/>

          </Typography>
        </AccordionDetails>
      </Accordion>  
      <Accordion expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
        <AccordionSummary
          expandIcon={expanded=='panel19'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>How long will I be covered by Bill Protection?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          You will be covered for 6 months starting from the date when you have successfully signed up for Bill Protection provided you make payment for the monthly premium accordingly.
                       <br/><br/>

          </Typography>
        </AccordionDetails>
      </Accordion>

        </AccordionDetails>
      </Accordion>



      <Accordion expanded={mainExpanded=='panel2'
        // expanded === 'panel2' || expanded=='panel21' || expanded=='panel22'|| expanded=='panel23'|| expanded=='panel24'|| expanded=='panel25'|| expanded=='panel26'|| expanded=='panel27'|| expanded=='panel28'
        } onChange={handleMainChange('panel2')}>
        <AccordionSummary
          expandIcon={mainExpanded=='panel2'
            // expanded=='panel2' || expanded=='panel21' || expanded=='panel22'|| expanded=='panel23'|| expanded=='panel24'|| expanded=='panel25'|| expanded=='panel26'|| expanded=='panel27'|| expanded=='panel28'
            ?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography style={{textAlign:'start'}} id='qtn-text' >POLICY PURCHASE & ELIGIBILITY</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Accordion expanded={expanded === 'panel21'} onChange={handleChange('panel21')}>
        <AccordionSummary
          expandIcon={expanded=='panel21'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>Who can be covered by Bill Protection?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          You can be covered by Bill Protection if you are:
          <li>Aged between 18 years old and 60 years old (last birthday) at time of application/renewal; and </li>
          <li>Malaysian, or foreigner who is permanent resident of Malaysia, work permit holder, pass holder or otherwise legally employed or legally residing in Malaysia; and</li>
          <li>Main Account holder or principal line holder of the eligible Account registered with Maxis.</li><br/>
         <p> Note: Each of your account under 1 Maxis ID is only allowed to be covered with 1 in-force certificate at any point of time. </p>
          
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel22'} onChange={handleChange('panel22')}>
        <AccordionSummary
          expandIcon={expanded=='panel22'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>What will I receive after I purchase Bill Protection?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          <p>
            You will receive your certificate, product disclosure sheet (PDS) and policy wording via e-mail within the same day. If you did not receive the policy documents, you may contact our AIA Online Customer Service via WhatsApp at 019 629 9660 or e-mail<b> my.aiaonline@aia.com</b> during the operating hours <b>(8:30 a.m. – 5:30 p.m. Mon to Thu, 8:30 a.m. – 4:30 p.m. on Fri, excluding public holidays).
            </b> </p>
          
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel23'} onChange={handleChange('panel23')}>
        <AccordionSummary
          expandIcon={expanded=='panel23'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>When is the effective date assuming I sign up for Bill Protection today?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          <p>

          Once you have successfully signed up for Bill Protection and AIA receives your application, your insurance coverage will be effective immediately. You will receive e-mail confirmation with your certificate number, effective date, and expiry date of the certificate. Your certificate, product disclosure sheet (PDS) and policy wording will be attached in the same e-mail.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel24'} onChange={handleChange('panel24')}>
        <AccordionSummary
          expandIcon={expanded=='panel24'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>Can I purchase Bill Protection for another Maxis ID user?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          <p>No, you are not allowed to purchase Bill Protection for another Maxis ID user.    </p>
          
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel25'} onChange={handleChange('panel25')}>
        <AccordionSummary
          expandIcon={expanded=='panel25'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>
          I have more than one Account, will I be allowed to sign up for more than one Bill Protection?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          <p>
          Yes, each Account under the same Maxis ID is eligible to be covered with 1 in-force certificate at any point of time. You can proceed to go through the application process again to get a Bill Protection plan for your other Accounts.
          </p>
          
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel26'} onChange={handleChange('panel26')}>
        <AccordionSummary
          expandIcon={expanded=='panel26'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>Can the certificate be renewed at the end of coverage period? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          <p>Yes, you are given an option for auto-renewal during your application. If you have opted in for auto-renewal, your certificate will be renewed automatically by AIA and you will receive a notification from us on the renewal. Otherwise, you will need to repurchase through Maxis or Hotlink  application again when your coverage expires. </p>
          <p>If you did not opt in for auto-renewal during your application and you change your mind during the certificate period, you may contact AIA Online Customer Service via WhatsApp at 019 629 9660 or e-mail my.aiaonline@aia.com during the operating hours (8:30 a.m. – 5:30 p.m. Mon to Thu, 8:30 a.m. – 4:30 p.m. on Fri, excluding public holidays) and we will help you to turn on your auto-renewal to enjoy continuous protection</p>
          <h8>Enjoy first month premium waived if you opt in for auto-renewal during our promotional period, terms and conditions apply. The premium waiver will be provided by AIA.</h8>
      
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel27'} onChange={handleChange('panel27')}>
        <AccordionSummary
          expandIcon={expanded=='panel27'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>Will I receive a reminder when my certificate is expiring?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          <p >You will receive an email reminder from us 30 days before your certificate expiry date. If you have opted in for auto-renewal, just continue to make payment on time and enjoy continuous protection.   </p>
          <p>If you have not opted in for auto renewal, you can follow the link provided that will guide you to repurchase through Maxis or Hotlink application again when your coverage expires.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
        </AccordionDetails>
        
      </Accordion>


      <Accordion expanded={mainExpanded=='panel3'
        // expanded === 'panel3'|| expanded=='panel31'|| expanded=='panel32'|| expanded=='panel33'|| expanded=='panel34'|| expanded=='panel35'|| expanded=='panel26'|| expanded=='panel37'|| expanded=='panel38'|| expanded=='panel39'|| expanded=='panel310'|| expanded=='panel311'|| expanded=='panel312'|| expanded=='panel313'|| expanded=='panel314'|| expanded=='panel315'|| expanded=='panel316'
        } onChange={handleMainChange('panel3')}>
        <AccordionSummary
          expandIcon={mainExpanded=='panel3'
            // expanded=='panel3'|| expanded=='panel31'|| expanded=='panel32'|| expanded=='panel33'|| expanded=='panel34'|| expanded=='panel35'|| expanded=='panel26'|| expanded=='panel37'|| expanded=='panel38'|| expanded=='panel39'|| expanded=='panel310'|| expanded=='panel311'|| expanded=='panel312'|| expanded=='panel313'|| expanded=='panel314'|| expanded=='panel315'|| expanded=='panel316'
            ?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography id='qtn-text'>SERVICING AND CLAIMS</Typography>
        </AccordionSummary>
        <Accordion expanded={expanded === 'panel31'} onChange={handleChange('panel31')}>
        <AccordionSummary
          expandIcon={expanded=='panel31'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>Can I cancel my certificate?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          <p>Yes, you may cancel your certificate at any time by contacting AIA Online Customer Service via WhatsApp at 019 629 9660 or <b>e-mail my.aiaonline@aia.com</b> during the operating hours <b>(8:30 a.m. – 5:30 p.m. Mon to Thu, 8:30 a.m. – 4:30 p.m. on Fri, excluding public holidays).</b> However, there shall be no refund of premium by AIA or Maxis and your certificate will remain in force until the end of the month where you have last paid for the monthly premium.  </p>
          
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel32'} onChange={handleChange('panel32')}>
        <AccordionSummary
          expandIcon={expanded=='panel32'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>Can I change to a plan with higher/lower monthly benefit amount? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          <p>If you have opted in for auto-renewal, you can contact us anytime and we will change the plan based on your request upon your certificate renewal but not during the midst of the current certificate period. However, if you prefer not to wait until the certificate renewal, you can cancel your existing certificate and repurchase the protection with your preferred plan via Maxis application.  </p>
          <p>For more information, please contact our dedicated AIA Online Customer Service via WhatsApp at 019 629 9660 or e-mail <b> my.aiaonline@aia.com</b> during the operating hours <b>(8:30 a.m. – 5:30 p.m. Mon to Thu, 8:30 a.m. – 4:30 p.m. on Fri, excluding public holidays).</b></p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel33'} onChange={handleChange('panel33')}>
        <AccordionSummary
          expandIcon={expanded=='panel33'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>What happens to my certificate if there is a change in my MSISDN that is used to bill for the premium and protected by the certificate?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          <p>Your existing certificate will be moved to the new MSISDN for the next premium installment billing and you will continue to enjoy the protection. Unless if you wish to terminate your protection, kindly contact AIA Online Customer Service to cancel your certificate.   </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel34'} onChange={handleChange('panel34')}>
        <AccordionSummary
          expandIcon={expanded=='panel34'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>What happens to my certificate if I change my Maxis post-paid plan to pre-paid plan?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          <p>Your existing certificate will be terminated. We will disburse the claim pay-out to your selected bank account, if applicable, in the event that the loss occurred during active certificate period when your certificate was still in force.    </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel35'} onChange={handleChange('panel35')}>
        <AccordionSummary
          expandIcon={expanded=='panel35'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>What happens to my certificate if I discontinue my service with Maxis?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          <p>Your existing certificate will be terminated. We will disburse the claim pay-out to your selected bank account, if applicable, in the event that the loss occurred during active certificate period when your certificate was still in force.    </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel36'} onChange={handleChange('panel36')}>
        <AccordionSummary
          expandIcon={expanded=='panel36'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>What happens to my certificate if there is a change in ownership of the current Maxis ID/MSISDN that is protected by the certificate?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
          <p>Your existing certificate will be terminated. We will disburse the claim pay-out to your selected bank account, if applicable, in the event that the loss occurred during active certificate period when your certificate was still in force.   </p>
          <p>If the new owner wish to be protected, he/she would need to purchase the preferred plan via Maxis and Hotlink application.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel37'} onChange={handleChange('panel37')}>
        <AccordionSummary
          expandIcon={expanded=='panel37'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>What do I need to do if there are changes to my personal details?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
<p>It is important that you inform us of any change in your contact details. You may request to update your personal details by contacting our dedicated AIA Online Customer Service via WhatsApp at 019 629 9660 or e-mail my.aiaonline@aia.com during the operating hours (8:30 a.m. – 5:30 p.m. Mon to Thu, 8:30 a.m. – 4:30 p.m. on Fri, excluding public holidays). 
</p>          
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel38'} onChange={handleChange('panel38')}>
        <AccordionSummary
          expandIcon={expanded=='panel38'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>How do I perform a claim for Bill Protection?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
<p>You need to complete the claim submission webpage and provide required supporting claim documents. You can obtain the link from the e-mail where you received your e-certificate upon insurance coverage. You can also submit your claim from the Bill Protection Banner under “My Certificate” in Maxis or Hotlink app. 
</p>  
<p>If you need any assistance on claims, you may contact AIA Online Customer Service via WhatsApp at 019 629 9660 or e-mail my.aiaonline@aia.com during the operating hours (8:30 a.m. – 5:30 p.m. Mon to Thu, 8:30 a.m. – 4:30 p.m. on Fri, excluding public holidays). </p>        
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel39'} onChange={handleChange('panel39')}>
        <AccordionSummary
          expandIcon={expanded=='panel39'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>How fast is the claim processing time?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
<p>You will receive an update of your claim status within 3 to 5 working days upon successful submission of claim.

</p>  
       
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel310'} onChange={handleChange('panel310')}>
        <AccordionSummary
          expandIcon={expanded=='panel310'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>How will the claim be paid out? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
<p>The claim pay-out will be disbursed to your Account directly, and your existing certificate will be terminated thereafter. If you no longer own an active Account, the claim pay-out will be disbursed to your selected bank account. 
</p>  
       
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel311'} onChange={handleChange('panel311')}>
        <AccordionSummary
          expandIcon={expanded=='panel311'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>Why is the claim pay-out amount different from my incurred Bill amount? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
<p>The claim will be paid out in one lump sum which is three times your selected monthly benefit amount, for example, 3 X RM 200 = RM600, regardless of your monthly Bill amount. If the claim pay-out is more than your actual monthly Bill amount, any excess amount will remain in your Account as credit to be carried forward to the next Bill cycle.
</p>  
       
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel312'} onChange={handleChange('panel312')}>
        <AccordionSummary
          expandIcon={expanded=='panel312'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>Am I entitled to additional claims if I have more than one line registered under my Account? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
<p>No, claim is only limited to 1 time per certificate for each Account regardless of number of registered lines.   
</p>  
       
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel313'} onChange={handleChange('panel313')}>
        <AccordionSummary
          expandIcon={expanded=='panel313'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>What are the documents required to support a claim?  </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
<p>You will need to provide the following documents to AIA for claim assessment, </p>  
<table style={{width:'100%',textAlign:'start',padding:'6px'}} id='table-text'>
  <tr>
    <th style={{backgroundColor:'#ba0b1a',color:'white'}}> Claim Type</th>
    <th style={{backgroundColor:'#ba0b1a',color:'white'}}>Claim Documents</th>
    
  </tr>
  <tr>
    <td>Hospitalisation for at least 3 consecutive days caused by accident benefit</td>
    <td>
    <li> Copy of IC (front & back)/Passport Copy</li>
    <li> Hospital admission/discharge slip</li>
    <li>Medical certificate</li>
    </td>
   
  
    
  </tr>
  <tr>
    <td>Hospitalisation for at least 3 consecutive days caused by infectious diseases benefit
</td>
    <td>
    <li> Copy of IC (front & back)/Passport Copy</li>
    <li> Hospital admission/discharge slip</li>
    <li>Medical certificate</li>
    </td>

   
  </tr>
  <tr>
    <td>Involuntary loss of employment benefit
</td>
    <td>
    <li> Copy of IC (front & back)/Passport Copy</li>
    <li>Employer's letter confirming the termination/dismissal</li>
    <li>Last 3 months salary slip</li>
    </td>

   
  </tr>
  
 
</table><br/><br/>
<p>Note: The list is not exhaustive. Kindly refer to policy contract for a full list of claim supporting documents required for each claim type. </p>
          </Typography>
        </AccordionDetails>
      </Accordion>

        <Accordion expanded={expanded === 'panel314'} onChange={handleChange('panel314')}>
        <AccordionSummary
          expandIcon={expanded=='panel314'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>When do I have to notify AIA of a claim? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
<p>All claims are advised to be submitted within 30 days of the incident date giving rise to a claim under Bill Protection.  

</p>  
       
          </Typography>
        </AccordionDetails>
      </Accordion>
           <Accordion expanded={expanded === 'panel315'} onChange={handleChange('panel315')}>
        <AccordionSummary
          expandIcon={expanded=='panel315'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>Where can I get further information? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
<p>For Product Information:   </p>  
<p>Please visit our product landing page in Maxis app for more information or you can find more information from the 
  <a 
  onClick={()=>{setPageUrl(productDis); handleOpen("Maxis-AIA-Bill-Protection-PDS")}}
  rel='noopener noreferrer' className="link-text"
  > Product Disclosure Sheet </a>
   and 
   <a
    onClick={()=>{setPageUrl(policy); handleOpen("Maxis-AIA-Bill-Protection-Policy")}}
      rel='noopener noreferrer' className="link-text"
   > Policy Wording.
   </a>
   </p>
       <p>For General Enquiries: </p>
       <p>Should you require additional information, please contact our dedicated AIA Online Customer Service via WhatsApp at 019 629 9660 or e-mail my.aiaonline@aia.com during the operating hours (8:30 a.m. – 5:30 p.m. Mon to Thu, 8:30 a.m. – 4:30 p.m. on Fri, excluding public holidays).</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel316'} onChange={handleChange('panel316')}>
        <AccordionSummary
          expandIcon={expanded=='panel316'?<RemoveIcon/>:<AddIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography  style={{textAlign:'start'}} id='qtn-text'>How fast should you expect a response for general enquiries? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography id='ans-text'>
<p>If you contact us during our operating hours via WhatsApp or email, we will get back to you within 3 mins via WhatsApp and 3 working days via email.

</p>  
       
          </Typography>
        </AccordionDetails>
      </Accordion>
      </Accordion>
      
      <Model url={pageUrl} open={modelOpen} handleOpen={handleOpen} handleClose={handleClose} type={pageType} />

    </div>
  );
}